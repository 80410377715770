import { Plan, PlansWOSingle } from '@/types/plan';

export function gtmAddToCart(plan: Plan | PlansWOSingle) {
  window.dataLayer = window.dataLayer || [];

  if (plan) {
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        items: [{
          item_id: plan.priceId,
          item_name: plan.title,
          price: plan.price,
          quantity: '1',
        }],
      },
    });
  }
}

export function gtmPurchase(accountId: string, subscriptionId: string, chargedAmount: number, plan: Plan) {
  window.dataLayer = window.dataLayer || [];

  if (accountId && subscriptionId && chargedAmount && plan) {
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: `${accountId}-${subscriptionId}`,
        currency: plan.currency || 'GBP',
        value: chargedAmount,
        items: [{
          item_id: plan.priceId,
          item_name: plan.title,
          price: chargedAmount,
          quantity: '1',
        }],
      },
    });

    window.dataLayer.push({
      event: 'conversion',
      send_to: 'AW-11333326379/uusvCIXElrUZEKusk5wq',
      value: chargedAmount,
      currency: plan.currency || 'GBP',
      transaction_id: `${accountId}-${subscriptionId}`,
    });
  }
}

export function gtmPurchaseUpgrade(accountId: string, subscriptionId: string, cost: number, plan: Plan) {
  window.dataLayer = window.dataLayer || [];

  if (subscriptionId && cost && plan) {
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: `${accountId}-${subscriptionId}`,
        currency: plan.currency || 'GBP',
        value: cost,
        items: [{
          item_id: plan.priceId,
          item_name: plan.title,
          price: plan.price,
          quantity: '1',
        }],
      },
    });

    window.dataLayer.push({
      event: 'conversion',
      send_to: 'AW-11333326379/uusvCIXElrUZEKusk5wq',
      value: cost,
      currency: plan.currency || 'GBP',
      transaction_id: `${accountId}-${subscriptionId}`,
    });
  }
}
